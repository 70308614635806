<template>
    <div>
        <template v-if="!Object.keys(event).length">
            <b-skeleton height="50px" class="mb-4" animation="wave" />
            <b-skeleton width="60%" animation="wave" />
            <b-skeleton width="70%" animation="wave" />
            <b-skeleton width="60%" animation="wave" />
        </template>
        <template v-else>
            <h2>{{ event.name }}</h2>
            <div class="mb-3" v-html="event.description"></div>
            <hr/>
            <b-row cols="1" cols-lg="2" cols-xl="3">
            <template v-if="event.location && event.location.place">
                <b-col>
                <h4>
                    <font-awesome-icon icon="map-marker" class="text-primary mr-2" /> Locatie
                </h4>
                <div class="mb-4">
                    <div class="font-weight-bold">{{ event.location.name }}</div>
                    <div class="text-muted">{{ event.location.place }}</div>
                    <div>
                    <a :href="`https://maps.google.com/?q=` + event.location.place" target="_blank">
                        Bekijk op de kaart
                        <font-awesome-icon icon="chevron-right" class="ml-1" />
                    </a>
                    </div>
                </div>
                </b-col>
            </template>
            <b-col>
                <h4>
                    <font-awesome-icon icon="user" class="text-primary mr-2" />
                    Organisator
                </h4>
                <div class="mb-3">
                    <div class="mb-2 font-weight-bold">{{ event.host.company_name }}</div>
                    <template v-if="$route.params.host">
                        <b-button :href="'https://www.tixgo.nl/host/' + event.host.slug + '-' + event.host.slug_hash" target="_blank" variant="outline-secondary" size="sm">
                        Bekijk profiel
                        </b-button>
                    </template>
                    <template v-else>
                        <b-button :to="'/host/' + event.host.slug + '-' + event.host.slug_hash" variant="outline-secondary" size="sm">
                        Bekijk profiel
                        </b-button>
                    </template>
                </div>
            </b-col>
            <b-col>
                <h4>
                <font-awesome-icon icon="times" class="text-primary mr-2" />
                Terugbetalingsbeleid
                </h4>
                <div class="mb-3">
                <template v-if="true == false">
                    <div class="text-muted">Neem contact op met de organisator om een terugbetaling aan te vragen. De Tixgo-servicekosten zijn niet-restitueerbaar.</div>
                </template>
                <template v-else>
                    <div class="text-muted">Helaas is retourneren niet mogelijk. Neem contact op met de organisator voor meer informatie.</div>
                </template>
                </div>
            </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        event: [Object],
    },
};
</script>