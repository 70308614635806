<template>
    <div>
        <template v-if="!event.slots">
            <b-row class="pt-2 pb-2" align-v="center">
                <b-col cols="auto">
                    <b-skeleton animation="wave" width="100px" class="mb-2" />
                    <b-skeleton animation="wave" width="100px" class="mb-2" />
                    <b-skeleton animation="wave" width="100px" class="mb-2" />
                </b-col>
                <b-col>
                    <b-skeleton animation="wave" width="50%" class="mb-2" />
                    <b-skeleton animation="wave" width="80%" class="mb-2" />
                    <b-skeleton animation="wave" width="60%" class="mb-2" />
                </b-col>
                <b-col cols="12" sm="auto">
                    <b-skeleton class="mt-2 ml-auto" type="button" width="100px" />
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <div v-for="(slot, skey) in event.slots" :key="skey">
                <b-row class="pt-2 pb-2" :class="(skey < event.slots.length - 1 ? 'border-bottom' : '')" align-v="center">
                    <b-col cols="auto">
                        <div class="text-center text-uppercase line-height">
                            <div>{{ slot.start_at|formatMonthDate }}</div>
                            <div class="fs-3">{{ slot.start_at|formatDayDate }}</div>
                            <div>{{ slot.start_at|formatYear }}</div>
                        </div>
                    </b-col>
                    <b-col>
                        <p class="text-muted mb-0">
                            {{ slot.start_at|formatTime }} uur
                        </p>
                        <h4 class="mb-0 fs-5">{{ event.name }}</h4>
                        <p v-if="event.location" class="mb-0 text-muted">{{ event.location.name }} | {{ event.location.place }}</p>
                    </b-col>
                    <b-col cols="12" sm="auto" class="text-right">
                        <b-button v-if="event.canceled_at" variant="danger" :disabled="true">
                            Geannuleerd
                        </b-button>
                        <b-button v-else-if="event.is_end_ticket_sale || event.is_sold_out" variant="dark" :disabled="true">
                            Uitverkocht
                        </b-button>
                        <b-button v-else :to="getSlotSlug(slot)" variant="primary">
                            Tickets
                            <font-awesome-icon icon="chevron-right" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            event: {
                default: null,
                type: Object
            },
        },
        methods: {
            getSlotSlug(slot) {
                if (this.$route.params.host) {
                    return '/' + this.$route.params.host + '/event/' + this.$route.params.event + '/' + slot.slug;
                }
                return '/event/' + this.$route.params.event + '/' + slot.slug;
            },
        },
    }
</script>