<template>
    <div>
        <div v-if="config.show_title" class="share-network-title">Vrienden uitnodigen</div>
        <div class="share-network-list">
            <a :href="shareLink(share)" rel="nofollow" target="_blank" class="share-network" :style="{ backgroundColor: share.color }" v-for="(share, key) in enabledNetworks" :key="key">
                <font-awesome-icon :style="{ 'padding': getCurrentSize }" class="share-network-icon" :icon="[share.icon_library, share.icon]" />
                <span class="share-network-label">{{ getShareName(share) }}</span>
            </a>
        </div>
    </div>
</template>

<script>
  export default {
    components: {},
    props: {
        url: { type: String, default: '' },
        title: { type: String, default: '' },
        description: { type: String, default: '' },
        quote: { type: String, default: '' },
        hashtags: { type: String, default: '' },
        twitterUser: { type: String, default: '' },
        media: { type: String, default: '' },
    },
    computed: {
        enabledNetworks: function() {
            return this.networks.filter(function(item) { return item.enabled; });
        },
        getCurrentSize: function() {
            if (this.config.size == 'sm') {
                return '7px';
            }
            return '10px';
        },
        getShareName: function() {
            return function(share) {
                if (this.config.show_text) {
                    if (share.text) {
                        return share.text + ' ' + share.name;
                    }
                }
                
                return share.name;
            }
        },
        shareLink: function() {
            return function(share) {
                let link = share.link;

                /**
                 * Twitter sharing shouldn't include empty parameter
                 * Source: https://github.com/nicolasbeauvais/vue-social-sharing/issues/143
                 */
                if (share.id === 'twitter') {
                    if (!this.hashtags.length) link = link.replace('&hashtags=@h', '')
                    if (!this.twitterUser.length) link = link.replace('@tu', '')
                }

                return link
                    .replace(/@tu/g, '&via=' + encodeURIComponent(this.twitterUser))
                    .replace(/@u/g, encodeURIComponent(this.url))
                    .replace(/@t/g, encodeURIComponent(this.title))
                    .replace(/@d/g, encodeURIComponent(this.description))
                    .replace(/@q/g, encodeURIComponent(this.quote))
                    .replace(/@h/g, this.encodedHashtags(share))
                    .replace(/@m/g, encodeURIComponent(this.media))
            }
        },
        encodedHashtags: function() {
            return function(share) {
                if (share.icon === 'facebook' && this.hashtags.length) {
                    return '%23' + this.hashtags.split(',')[0]
                }

                return this.hashtags
            }
        }
    },
    data() {
      return {
        config: {
            size: 'sm',
            show_text: false,
            show_title: true,
        },
        /**
         * We use shorter names to reduce the final bundle size
         *
         * Properties:
         * @u = url
         * @t = title
         * @d = description
         * @q = quote
         * @h = hashtags
         * @m = media
         * @tu = twitterUser
         */
        // https://www.npmjs.com/package/vue-social-sharing?activeTab=code 
        networks: [
            {
                id: 'facebook',
                enabled: true,
                icon_library: 'brands',
                icon: 'facebook',
                name: 'Facebook',
                text: 'Share on',
                link: 'https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h',
                color: '#1877F2',
            },
            {
                id: 'whatsapp',
                enabled: true,
                icon_library: 'brands',
                icon: 'whatsapp',
                name: 'Whatsapp',
                text: 'Share on',
                link: 'https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d',
                color: '#25D366',
            },
            {
                id: 'twitter',
                enabled: true,
                icon_library: 'brands',
                icon: 'twitter',
                name: 'Twitter',
                text: 'Share on',
                link: 'https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu',
                color: '#1DA1F2',
            },
            {
                id: 'linkedin',
                enabled: true,
                icon_library: 'brands',
                icon: 'linkedin',
                name: 'LinkedIn',
                text: 'Share on',
                link: 'https://www.linkedin.com/sharing/share-offsite/?url=@u',
                color: '#007BB5',
            },
            {
                id: 'email',
                enabled: true,
                icon_library: 'solid',
                icon: 'envelope',
                name: 'Email',
                text: 'Share by',
                link: 'mailto:?subject=@t&body=@d%0D%0A@u',
                color: '#333333',
            }
        ]
      }
    }
}
</script>

<style scoped>
.share-network-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}
.share-network-title{
    color:#6c757d;
    margin-bottom:8px;
}
@media screen and (max-width:767px) {
    .share-network-title{
        text-align:center;
    }
}
.share-network{
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 0.25rem;
    overflow: hiconden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none;
}
.share-network-icon{
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
}
.share-network-label{
    padding: 0 10px;
    flex: 1 1;
    font-weight: 500;
}
</style>