<template>
  <div>
    <Header :title="event.name ? event.name : null" :lead="(event.location ? event.location.name : null) + (event.location ? ' - ' + event.location.place : null)" :header="event.header_image ? `https://api.tixgo.nl/files/` + event.header_image : null" />
    <b-container fluid class="header-pull">
      <SaleClosedAlert :event="event" />
      <ValidationObserver ref="form1" slim>
          <b-card class="mb-3">
            <EventDetails :event="event" />
          </b-card>
          <b-card class="mb-3" header="Kies je datum" header-tag="h4">
            <SlotsListing :event="event" />
          </b-card>
        </ValidationObserver>

        <div class="text-center mt-5 mb-5">
          <SocialShare :title="'Uitnodiging voor ' + event.name" :description="'Ik wil supergraag dat je mee gaat naar ' + event.name" :url="getFullUrl" />
        </div>
    </b-container>
  </div>
</template>
  
<script>
  import { ValidationObserver } from 'vee-validate';
  
  import EventDetails from '@/shared/components/EventDetails'
  import SocialShare from '@/shared/components/SocialShare'
  import Header from '@/shared/components/Header'
  import SaleClosedAlert from '@/shared/components/SaleClosedAlert'
  import SlotsListing from '@/shared/components/SlotsListing'

  export default {
    metaInfo: {
      title: 'Event',
    },
    components: {
      ValidationObserver,
      //ValidationProvider,
      EventDetails,
      SocialShare,
      Header,
      SaleClosedAlert,
      SlotsListing,
    },
    data() {
      return {
        event: {},
      }
    },
    computed: {
      getFullUrl() {
        return window.location.href;
      },
      calcTicketPrice() {
        return function(ticket) {
          return Number(ticket.price) + Number(this.event.customer_ticket_fee);
        }
      },
      calcTicketPriceWithAmount() {
        return function(ticket) {
          return (Number(ticket.price) + Number(this.event.customer_ticket_fee)) * Number(ticket.amount);
        }
      }
    },
    methods: {
      getEvent: function() {
        this.$axios.get('https://api.tixgo.nl/event/' + this.$route.params.event)
          .then( response => {
              this.event = response.data;
          }
        ).catch((error) => {
          if (error.response.status == '404') { 
            this.$router.push('/404');
          } else {
            this.$router.push('/500');
          }
        });
      },
    },
    created() {
      this.getEvent();
    }
  }
</script>

<style scoped>
  .line-height{
    line-height:1.2;
  }
</style>