<template>
    <div>
        <template v-if="Object.keys(event).length">
            <b-alert v-if="event.canceled_at" class="h5 shadow" variant="danger" show>
                <b-row align-v="center">
                    <b-col class="text-center" md="1">
                        <font-awesome-icon icon="exclamation-triangle" size="2x" />
                    </b-col>
                    <b-col class="text-center text-md-left">
                        Dit evenement is door de organisator geannuleerd.
                    </b-col>
                </b-row>
            </b-alert>
            <b-alert v-if="event.is_end_ticket_sale" class="h5 shadow" variant="warning" show>
                <b-row align-v="center">
                    <b-col class="text-center" md="1">
                        <font-awesome-icon icon="exclamation-triangle" size="2x" />
                    </b-col>
                    <b-col class="text-center text-md-left">
                        De verkoop voor dit evenement is gesloten.
                    </b-col>
                </b-row>
            </b-alert>
            <b-alert v-if="!event.is_end_ticket_sale && !event.is_start_ticket_sale" class="h5 shadow" variant="warning" show>
                <b-row align-v="center">
                    <b-col class="text-center" md="1">
                        <font-awesome-icon icon="exclamation-triangle" size="2x" />
                    </b-col>
                    <b-col class="text-center text-md-left">
                        <div>Tickets zijn nog niet beschikbaar, want de online verkoop is nog niet gestart.</div>
                        <div class="pt-2">
                            De verkoop start op {{ event.start_sale_at|formatFullTextDate }} om {{ event.start_sale_at|formatTime }} uur
                        </div>
                    </b-col>
                </b-row>   
            </b-alert>
        </template>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            event: Object,
        },
        data() {
            return {}
        },
        computed: {},
        methods: {},
        created () {}
    }
</script>